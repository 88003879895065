import { PageHeader } from 'components/PageHeader';
import {
  DocumentCategory,
  GetIncomingDocumentQuery,
  IncomingDocumentStatus,
  IncomingDocumentType,
  useApproveIncomingDocMutation,
  useGetIncomingDocumentQuery,
  useLinkIncomingDocumentToOrderMutation,
  useRejectIncomingDocMutation,
  useRotateDocumentPagesMutation,
  useSplitIncomingDocumentMutation,
  useUpdateIncomingDocumentTypeMutation,
  useVerifyDocumentCategoryMutation,
} from 'generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { invariant, nullthrows } from '@utils/invariant';
import classNames from '@utils/classnames';
import { Formik } from 'formik';
import { Check } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { getDisplayError } from '../../../utils/get-display-error';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { DataField } from '../../../components/DataField';
import { IncomingDocumentStatusToText, IncomingDocumentTypeToText } from '../utils/status';
import { FormDialog } from '../../../components/dialog/FormDialog';
import { MinimalOrderComboboxField } from '../components/MinimalOrderComboboxField';
import { useMinimalGeneralSettings } from '../../../contexts/minimal-settings-context';
import { DocumentPreview, IDocumentPreviewProps } from '../../document/components/DocumentPreview';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { DOCUMENT_CATEGORY_VALUES } from '../../document/constants.client';
import { Button } from '../../../components/button/Button';
import { formatDate } from '../../../utils/date';
import { formatNumber } from '../../../utils/number';
import { DocumentSplitterDialog, IParsedPageRange } from '../components/DocumentSplitterDialog';
import { LinkButton } from '../../../components/button/ButtonLink';
import { PageHeading } from 'components/PageHeading';

export type IncomingDocument = NonNullable<GetIncomingDocumentQuery['incomingDocument']>;

const LinkOrderDialog: React.FC<{ incomingDoc: IncomingDocument; isBlocked: boolean }> = (props) => {
  const { incomingDoc, isBlocked } = props;
  const navigate = useNavigate();

  const { refreshIncomingDocCount } = useMinimalGeneralSettings();
  const [, linkIncomingDoc] = useLinkIncomingDocumentToOrderMutation();

  return (
    <FormDialog
      triggerText="Link Order"
      triggerColor={incomingDoc.status !== IncomingDocumentStatus.Approved ? 'primary' : 'default'}
      title={`Link ${IncomingDocumentTypeToText[incomingDoc.type]} document aan order`}
      submitText="Link Order"
      initialValues={{ order: incomingDoc.order }}
      isDisabled={isBlocked}
      triggerTitle={
        isBlocked
          ? 'Kan dit document niet linken, bevat paginas die niet in een order mogen.'
          : 'Link document aan een order'
      }
      onSubmit={async (values) => {
        try {
          const order = nullthrows(values.order, 'Er is geen order geselecteerd');
          const res = await linkIncomingDoc({
            incomingDocId: incomingDoc.id,
            orderId: order.id,
          });
          if (res.error) {
            throw res.error;
          }
          refreshIncomingDocCount();
          toast.success('Order gelinkt');
          navigate('..');
        } catch (err) {
          toast.error('Kon order niet linken: ' + getDisplayError(err));
        }
      }}
    >
      <MinimalOrderComboboxField name="order" />
    </FormDialog>
  );
};

const IncomingDocPage = () => {
  const navigate = useNavigate();
  const { incomingDocId } = useParams<{ incomingDocId: string }>();
  invariant(incomingDocId);
  const [{ data }] = useGetIncomingDocumentQuery({
    variables: {
      id: incomingDocId,
    },
  });
  const [, verifyDocumentCategory] = useVerifyDocumentCategoryMutation();
  const [pageRotations, setPageRotations] = useState<NonNullable<IDocumentPreviewProps['rotations']>>([]);
  const [previewKey, setPreviewKey] = useState(0);

  const incomingDoc = nullthrows(data?.incomingDocument, 'Incoming document not found');

  const { refreshIncomingDocCount } = useMinimalGeneralSettings();
  const [, rejectIncomingDoc] = useRejectIncomingDocMutation();
  const [, approveIncomingDoc] = useApproveIncomingDocMutation();
  const [, updateIncomingDocType] = useUpdateIncomingDocumentTypeMutation();
  const [, splitIncomingDoc] = useSplitIncomingDocumentMutation();
  const [rotateDocState, rotateDocumentPages] = useRotateDocumentPagesMutation();

  const originalType = incomingDoc.type;
  const newType = useMemo(() => {
    const filtered = incomingDoc.pages.filter((v) => v.prediction.confidence > 0.8).map((p) => p.prediction);

    const containsInvoice = filtered.find(
      (p) => p.category === DocumentCategory.Invoice || p.category === DocumentCategory.CreditNote,
    );
    if (containsInvoice) {
      return IncomingDocumentType.Invoice;
    }

    const containsCmr = filtered.find(
      (p) => p.category === DocumentCategory.Cmr || p.category === DocumentCategory.DeliveryNote,
    );
    if (containsCmr) {
      return IncomingDocumentType.Cmr;
    }

    return IncomingDocumentType.Other;
  }, [incomingDoc.pages]);

  const linkingBlocked = !!incomingDoc.pages.find((p) => {
    return (
      p.prediction.category === DocumentCategory.Invoice || p.prediction.category === DocumentCategory.TransportOrder
    );
  });

  const executeDocumentSplit = async (pageRanges: IParsedPageRange[]) => {
    const result = await splitIncomingDoc({
      incomingDocId: incomingDoc.id,
      ranges: pageRanges.map((v) => {
        return {
          startPage: v.start,
          endPage: v.end,
        };
      }),
    });
    if (result.error) {
      throw result.error;
    }
    navigate('..');
  };

  const persistDocumentRotations = async () => {
    try {
      const rotations = pageRotations.filter((v) => v.rotation !== 0);
      const result = await rotateDocumentPages({
        id: incomingDoc.document.id,
        pages: rotations.map((v) => {
          return v.pageNumber;
        }),
        rotation: rotations[0].rotation,
      });
      if (result.error) {
        throw result.error;
      }
      setPreviewKey((prev) => prev + 1);
      setPageRotations([]);
      toast.success('Document rotaties opgeslagen');
    } catch (err) {
      toast.error(`Kon document rotaties niet opslaan: ${getDisplayError(err)}`);
    }
  };

  const order = incomingDoc.order;
  const purchaseInvoice = incomingDoc.purchaseInvoice;
  return (
    <>
      <PageHeader title={`Inkomend Document - ${incomingDoc.document.name}`} />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Inkomende Documenten',
              to: '/internal/incoming-docs',
            }}
            currentItem={incomingDoc.document.name}
          />
        }
      />

      <div className="px-4">
        <div className="flex justify-between gap-4 mb-4">
          <div className="flex gap-8">
            <DataField title="Status">{IncomingDocumentStatusToText[incomingDoc.status]}</DataField>
            <DataField title="Document type">{IncomingDocumentTypeToText[incomingDoc.type]}</DataField>
            {order ? (
              <>
                <DataField title="Order">{order.orderNumber}</DataField>
                <DataField title="Klant">{`${order.customer?.name ?? ''} ${order.customerRef}`}</DataField>
                {!!order.supplier && <DataField title="Vervoerder">{order.supplier.name}</DataField>}
              </>
            ) : null}
            {purchaseInvoice ? (
              <>
                <DataField title="Aankoop">{purchaseInvoice.invoiceNumber || purchaseInvoice.id}</DataField>
                <DataField title="Vervoerder">{purchaseInvoice.supplier.name}</DataField>
              </>
            ) : null}
          </div>
          <div className="flex justify-end gap-4">
            {purchaseInvoice && (
              <LinkButton to={`/internal/finance/purchases/${purchaseInvoice.id}`} color="primary">
                Aankoopfactuur
              </LinkButton>
            )}

            {incomingDoc.status === IncomingDocumentStatus.New && (
              <>
                {(incomingDoc.type === IncomingDocumentType.Cmr || incomingDoc.type === IncomingDocumentType.Other) && (
                  <LinkOrderDialog incomingDoc={incomingDoc} isBlocked={linkingBlocked} />
                )}

                {!!pageRotations.length && (
                  <Button
                    color="primary"
                    onTrigger={() => {
                      persistDocumentRotations();
                    }}
                    isLoading={rotateDocState.fetching}
                  >
                    Pas document rotaties aan
                  </Button>
                )}

                {incomingDoc.type === IncomingDocumentType.Invoice && (
                  <ConfirmDialog
                    triggerColor="primary"
                    triggerText="Goedkeuren"
                    title="Ben je zeker dat je dit document wilt goedkeuren?"
                    submitText="Goedkeuren"
                    description="Ben je zeker dat je dit document wilt goedkeuren?"
                    onSubmit={async () => {
                      try {
                        const result = await approveIncomingDoc({
                          incomingDocId: incomingDoc.id,
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        refreshIncomingDocCount();
                        toast.success('Document goedgekeurd');
                        navigate('..');
                      } catch (err) {
                        toast.error(`Kon document niet goedkeuren: ${getDisplayError(err)}`);
                      }
                    }}
                  />
                )}

                {originalType !== newType && (
                  <ConfirmDialog
                    triggerColor="primary"
                    triggerText="Werk document type bij"
                    title={`Ben je zeker dat je het document type wilt veranderen naar ${IncomingDocumentTypeToText[newType]}?`}
                    submitText="Pas type aan"
                    description="Ben je zeker dat je het document type wilt veranderen? Dit zal ervoor zorgen dat zaken zoals automatische herkenning gedraaid worden op het nieuwe type."
                    onSubmit={async () => {
                      try {
                        const result = await updateIncomingDocType({
                          incomingDocId: incomingDoc.id,
                          newType,
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        refreshIncomingDocCount();
                        toast.success('Document bijgewerkt');
                        navigate('..');
                      } catch (err) {
                        toast.error(`Kon document niet bijwerken: ${getDisplayError(err)}`);
                      }
                    }}
                  />
                )}

                {incomingDoc.pages.length > 1 && (
                  <DocumentSplitterDialog pages={incomingDoc.pages} executeSplit={executeDocumentSplit} />
                )}

                {
                  <ConfirmDialog
                    triggerColor="default"
                    triggerText="Weiger document"
                    title="Ben je zeker dat je dit document wilt weigeren?"
                    submitText="Weiger document"
                    description="Ben je zeker dat je dit document wilt weigeren?"
                    onSubmit={async () => {
                      try {
                        const result = await rejectIncomingDoc({
                          incomingDocId: incomingDoc.id,
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        refreshIncomingDocCount();
                        toast.success('Document geweigerd');
                        navigate('..');
                      } catch (err) {
                        toast.error(`Kon document niet weigeren: ${getDisplayError(err)}`);
                      }
                    }}
                  />
                }
              </>
            )}
          </div>
        </div>

        {!purchaseInvoice && incomingDoc.type === IncomingDocumentType.Invoice && (
          <div className="my-4 bg-feedback-negative-04 py-1 px-2 rounded text-white">
            Automatisch aanmaken van aankoopfactuur mislukt.
          </div>
        )}

        {purchaseInvoice && incomingDoc.invoiceTotalMismatch && (
          <div className="my-4 bg-feedback-negative-04 py-1 px-2 rounded text-white">
            Totaal bedrag op document en verwacht totaal bedrag komen niet overeen.
          </div>
        )}

        <div className="flex gap-8">
          <div style={{ width: 802 }}>
            <DocumentPreview
              key={previewKey}
              document={incomingDoc.document}
              rotations={pageRotations}
              onRotate={(pageNumber, newRotation) => {
                setPageRotations((prev) => {
                  if (newRotation === 0) {
                    return prev.filter((v) => v.pageNumber !== pageNumber);
                  }

                  const existing = prev.find((v) => v.pageNumber === pageNumber);
                  if (!existing) {
                    return [...prev, { pageNumber, rotation: newRotation }];
                  } else {
                    existing.rotation = newRotation;
                    return [...prev];
                  }
                });
              }}
            />
          </div>
          <div className="w-full">
            <div className="mb-8 w-full max-w-96">
              <div className="heading-two mb-2">AI Predictions</div>
              <div className="w-full">
                {incomingDoc.pages
                  .sort((a, b) => a.pageIndex - b.pageIndex)
                  .map((p) => {
                    const roundedConfidence = Math.round(p.prediction.confidence * 100);
                    return (
                      <Formik
                        initialValues={{
                          category: DOCUMENT_CATEGORY_VALUES.find((v) => v.key === p.prediction.category),
                        }}
                        onSubmit={async (newValues) => {
                          try {
                            if (!newValues.category) {
                              throw new Error('Geen categorie geselecteerd');
                            }

                            const result = await verifyDocumentCategory({
                              id: p.prediction.id,
                              category: newValues.category.key as DocumentCategory,
                            });
                            if (result.error) {
                              throw result.error;
                            }
                            toast.success('Categorie geverifieerd');
                          } catch (err) {
                            toast.error(`Kon categorie niet verifiëren: ${getDisplayError(err)}`);
                          }
                        }}
                        key={p.id}
                      >
                        {({ handleSubmit, isSubmitting }) => {
                          return (
                            <form className="w-full flex items-center gap-4" onSubmit={handleSubmit}>
                              <div className="whitespace-nowrap">{`Pagina ${p.pageIndex + 1}`}</div>
                              <SimpleSelectField
                                labelText="Categorie"
                                items={DOCUMENT_CATEGORY_VALUES}
                                name="category"
                              />
                              <div
                                className={classNames('font-medium', {
                                  'text-feedback-positive': roundedConfidence > 95,
                                  'text-feedback-negative-04': roundedConfidence < 90,
                                })}
                              >{`${roundedConfidence}%`}</div>
                              <div>
                                <Button
                                  type="submit"
                                  color={p.prediction.isVerified ? 'default' : 'primary'}
                                  isLoading={isSubmitting}
                                >
                                  <Check className="w-4 h-4" />
                                </Button>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    );
                  })}
              </div>

              {incomingDoc.invoicePrediction && (
                <div>
                  <DataField title="Datum">
                    {incomingDoc.invoicePrediction.date ? formatDate(incomingDoc.invoicePrediction.date) : '-'}
                  </DataField>
                  <DataField title="Factuur nummer">{incomingDoc.invoicePrediction.invoiceNr ?? '-'}</DataField>
                  <DataField title="Klant naam">{incomingDoc.invoicePrediction.customerName ?? '-'}</DataField>
                  <DataField title="BTW Nummer Klant">{incomingDoc.invoicePrediction.customerTaxId ?? '-'}</DataField>
                  <DataField title="Leverancier naam">{incomingDoc.invoicePrediction.supplierName ?? '-'}</DataField>
                  <DataField title="BTW Nummer Leverancier">
                    {incomingDoc.invoicePrediction.supplierTaxId ?? '-'}
                  </DataField>
                  <DataField title="Totaal bedrag">
                    {incomingDoc.invoicePrediction.totalAmount
                      ? `€ ${formatNumber(incomingDoc.invoicePrediction.totalAmount, 2, {
                          decimalSeperator: ',',
                        })}`
                      : '-'}
                  </DataField>
                  <DataField title="Transport Orders">
                    {incomingDoc.invoicePrediction.transportOrderRefs.join(', ')}
                  </DataField>
                  <DataField title="Confidence">{`${Math.round(
                    incomingDoc.invoicePrediction.confidence * 100,
                  )}%`}</DataField>
                </div>
              )}
            </div>

            <div className="heading-two mb-2">Email Inhoud</div>
            <div>Van: {incomingDoc.from}</div>
            <div>Onderwerp: {incomingDoc.subject}</div>
            <div className="whitespace-pre-line">{incomingDoc.content}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomingDocPage;
